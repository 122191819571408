.latestUpdateContainer {
  .imageBox {
    width: 100%;
    height: 100%;
    span {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
